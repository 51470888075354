<template>
  <div class="main">
    <a-form id="formLogin" class="user-layout-login" ref="formLogin" :form="form" @submit="handleSubmit">
      <a-alert
        v-if="isLoginError"
        type="error"
        showIcon
        style="margin-bottom: 24px"
        :message="$t('user.login.message-invalid-credentials')"
      />
      <!-- 账号 -->
      <a-form-item>
        <div class="allInputBox">
          <a-input
            size="large"
            type="text"
            placeholder="请输入注册邮箱"
            v-decorator="[
              'username',
              {
                rules: [{ required: true, message: '请输入注册邮箱' }, { validator: handleUsernameOrEmail }],
                validateTrigger: 'change'
              }
            ]"
          >
            <!-- <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" /> -->
          </a-input>
        </div>
      </a-form-item>
      <!-- 密码 -->
      <a-form-item>
        <div class="allInputBox">
          <a-input-password
            size="large"
            placeholder="请输入密码"
            v-decorator="['password', { rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur' }]"
          >
            <!-- <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" /> -->
          </a-input-password>
        </div>
      </a-form-item>
      <!-- 验证码 -->
      <a-form-item>
        <div class="code allInputBox">
          <a-input
            autocomplete="off"
            size="large"
            placeholder="请输入验证码"
            ref="codeInput"
            v-decorator="['code', { rules: [{ required: true, message: '请输入验证码' }], validateTrigger: 'blur' }]"
          ></a-input>
          <img @click="getCode" :src="codeUrl" alt="" />
        </div>
      </a-form-item>
      <a-form-item style="margin-top: 14px">
        <a-button
          size="large"
          type="primary"
          htmlType="submit"
          class="login-button"
          :loading="state.loginBtn"
          :disabled="state.loginBtn"
        >登录</a-button
        >
      </a-form-item>
      <div class="user-login-other">
        <router-link :to="{ name: 'password', params: { user: 'aaa' } }" class="forge-password" style="float: left" v-if="updatePassword">
          忘记密码
        </router-link>
        <router-link class="register" :to="{ name: 'register' }" v-if="allowRegister">
          <span style="color: #000000">还没账户？</span>{{ $t('user.login.signup') }}</router-link
        >
      </div>
    </a-form>
  </div>
</template>

<script>
import TwoStepCaptcha from '@/components/tools/TwoStepCaptcha'
import { mapActions, mapState } from 'vuex'
import { timeFix } from '@/utils/util'
import { getSmsCaptcha, getCodeImg } from '@/api/login'
import { Encrypt } from '@/utils/jsencrypt'
export default {
  components: {
    TwoStepCaptcha
  },
  data () {
    return {
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginError: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      },
      codeUrl: '',
      uuid: ''
    }
  },
  created () {
    this.getCode()
  },
  methods: {
    ...mapActions(['Login', 'Logout']),
    // 获取验证图片
    getCode () {
      getCodeImg().then((res) => {
        this.codeUrl = 'data:image/gif;base64,' + res.data.img
        this.uuid = res.data.uuid
        // 更新图片后聚焦
        this.$nextTick(() => {
          this.$refs.codeInput.focus()
        })
      })
    },
    // handler
    handleUsernameOrEmail (rule, value, callback) {
      const { state } = this
      const regex = /^[A-Za-zd0-9_-]+([-_.][A-Za-zd0-9_-]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      if (regex.test(value)) {
        state.loginType = 0
      } else {
        state.loginType = 1
      }
      callback()
    },
    handleTabClick (key) {
      this.customActiveKey = key
      // this.form.resetFields()
    },
    handleSubmit (e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
        customActiveKey,
        Login
      } = this
      state.loginBtn = true
      const validateFieldsKey =
        customActiveKey === 'tab1' ? ['username', 'password', 'code', 'rememberMe'] : ['mobile', 'captcha']
      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        if (!err) {
          const loginParams = { ...values }
          delete loginParams.username
          delete loginParams.uuid
          loginParams['username'] = values.username
          loginParams.uuid = this.uuid
          loginParams.password = Encrypt(values.password)
          Login(loginParams)
            .then((res) => {
              if (res.code !== 200) {
                this.getCode()
              } else if (res.code === 200) {
                this.loginSuccess(res)
              }
            })
            .catch((err) => this.requestFailed(err))
            .finally(() => {
              state.loginBtn = false
            })
        } else {
          setTimeout(() => {
            state.loginBtn = false
          }, 600)
        }
      })
    },
    getCaptcha (e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state
      } = this

      validateFields(['mobile'], { force: true }, (err, values) => {
        if (!err) {
          state.smsSendBtn = true

          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60
              state.smsSendBtn = false
              window.clearInterval(interval)
            }
          }, 1000)

          const hide = this.$message.loading('验证码发送中..', 0)
          getSmsCaptcha({ mobile: values.mobile })
            .then((res) => {
              setTimeout(hide, 2500)
              this.$notification['success']({
                message: '提示',
                description: '验证码获取成功，您的验证码为：' + res.result.captcha,
                duration: 8
              })
            })
            .catch((err) => {
              setTimeout(hide, 1)
              clearInterval(interval)
              state.time = 60
              state.smsSendBtn = false
              this.requestFailed(err)
            })
        }
      })
    },
    stepCaptchaSuccess () {
      this.loginSuccess()
    },
    stepCaptchaCancel () {
      this.Logout().then(() => {
        this.loginBtn = false
        this.stepCaptchaVisible = false
      })
    },
    loginSuccess (res) {
      this.$router.push({ path: '/' })
      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        this.$notification.success({
          message: '欢迎',
          description: `${timeFix()}，欢迎回来`
        })
      }, 1000)
      this.isLoginError = false
    },
    requestFailed (err) {
      this.isLoginError = true
      this.$notification['error']({
        message: '错误',
        description: ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试',
        duration: 4
      })
    }
  },
  computed: {
    ...mapState({
      allowRegister: (state) => state.autoweb.allowRegister,
      updatePassword: (state) => state.autoweb.updatePassword
    })
  }
}
</script>

<style lang="less" scoped>

.main {
  @media (max-width: 998px) {
    width: 100%;
  }
  .user-layout-login {
    .code {
      display: flex;
      align-items: center;
      input {
        width: 60%;
        margin-right: 20px;
        border-radius: 12px;
        // border: none;
        padding: 25px;
      }
      img {
        width: 0;
        flex-grow: 1;
        height: 50px;
        border-radius: 12px;
        margin-top: -12px;
      }
    }
    label {
      font-size: 14px;
    }

    .getCaptcha {
      display: block;
      width: 100%;
      height: 40px;
    }

    .forge-password {
      font-size: 14px;
    }

    button.login-button {
      padding: 0 15px;
      font-size: 16px;
      height: 50px;
      width: 100%;
      border-radius: 12px;
    }

    .user-login-other {
      text-align: left;
      margin-top: 24px;
      line-height: 22px;
      padding-bottom: 12vh;
      z-index: 999;
      .item-icon {
        font-size: 24px;
        color: fade(@primary-color, 20%);
        margin-left: 16px;
        vertical-align: middle;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: @primary-color;
        }
      }

      .register {
        float: right;
      }
    }
  }
  ::v-deep {
    .allInputBox {
      input {
        background: #fafafa;
        outline: 1px solid #eee;
        border-radius: 12px;
        border: none;
        padding: 25px;
        margin-bottom: 10px;
        color: #999999;
      }
      input:-webkit-autofill,
      input[type='password'] {
        /* 字体颜色，需要设置，否则会变成黑色 */
        // -webkit-text-fill-color: #333 !important;
        /* 变成透明背景色的固定写法，只针对表单自动填充的背景色 */
        transition: background-color 5000s ease-in-out 0s;
      }
    }
    .has-error {
      .ant-form-explain {
        margin-left: 10px;
      }
    }
  }
}
</style>
